.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1; /* ie 6/7 */
}

* {
	outline: none;
}

.mobile-only {
	@include mq(m){
		display: none;
	}
}

.center {
	text-align: center;
}
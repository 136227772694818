@mixin mq($point) {
	@if $point == xl {
		@media (min-width: 1200px) { @content; }
	}
	@if $point == l {
		@media (min-width: 992px) { @content; }
	}
	@else if $point == m {
		@media (min-width: 768px)  { @content; }
	}
	@else if $point == s {
		@media (min-width: 576px)  { @content; }
	}
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin linearGradient($direction, $colour1, $colour2){
    background: $colour1;
    background: linear-gradient(to $direction, $colour1 0%, $colour2 100%);
}

/* https://css-tricks.com/snippets/sass/centering-mixin/ */
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* https://css-tricks.com/snippets/sass/covering-mixin/ */
@mixin coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/ */
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/* ==========================================================================
   Variables
   ========================================================================== */

$primary: #1a1a1a;
$secondary: #1a1a1a;
$tertiary: #1a1a1a;
$heading: 'Raleway','Helvetica Neue', Helvetica, Arial, sans-serif;
$body: 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* ==========================================================================
   Imports
   ========================================================================== */

@import 'wordpress';
@import 'bootstrap';
@import 'mixins';
@import 'hamburger';
@import 'helpers';
@import 'animate';
@import 'slick';
@import 'slick-theme';
@import 'forms';
@import 'font-awesome';

button,html,input,select,textarea{color:#222}html{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;font-size:1em;line-height:1.4}::-moz-selection{background:#b3d4fc;text-shadow:none}::selection{background:#b3d4fc;text-shadow:none}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}audio,canvas,img,video{vertical-align:middle}fieldset{border:0;margin:0;padding:0}textarea{resize:vertical}.browsehappy{margin:.2em 0;background:#ccc;color:#000;padding:.2em 0;}

/* ==========================================================================
   Main Styles
   ========================================================================== */

body,p,ul,li,input,textarea  {
	font-family: $body;
	font-size: 100%;
	line-height: 1.4;
	color: #1a1a1a;
	-webkit-font-smoothing: antialiased;
}


body * {
	box-sizing: border-box;
}

.loader {
	position: fixed;
	display: block;
	width: 100%;height: 100%;
	background-color: #fff;
	z-index: 9999;
}

a {
	transition: all .5s;
	color: $primary;
	text-decoration: none;
}
a:active,a:hover {
	text-decoration: none;
}
a:focus {
	outline: none;
}



.container-fluid {
	max-width: 1000px;
	padding: 50px 20px;
	position: relative;
	z-index: 2;
	&.full-width {
		max-width: 100%;
		padding: 0;
		.column {
			padding: 5%;
		}
	}
	&.narrow {
		max-width: 800px;
	}
}

section {
	position: relative;
	&.padding {
		padding: 50px 0;
	}
	&.one-column {
		.container-fluid {
			padding: 80px 20px;
		}
	}
	&.two-columns {
		
	}
}

.light-grey-bg {
	background: #f2f2f2;
}
.dark-grey-bg {
	background: #333;
}


h1,h2,h3,h4,h5 {
	font-family: $heading;
	color: $primary;
	font-weight: normal;
	text-align: center; 
	&:first-of-type {
		margin-top: 0;
	}
}


h1 {
	font-size: 24px;
	font-weight: 300; 
	text-transform: uppercase;
	margin: 2em 0 1em 0;  
	@include mq(s){font-size: 28px;}
	@include mq(m){font-size: 36px;}
	@include mq(l){font-size: 42px;}
	span {
		font-weight: 700;
	}
}


h2 {
	font-size: 22px;
	margin: 2em 0;
	font-weight: 700; 
	text-transform: uppercase;
	letter-spacing: 2px;
	@include mq(s){font-size: 24px;}
	@include mq(m){font-size: 28px;}
	@include mq(l){font-size: 32px;}
}
h3 {
	font-size: 18px;
	@include mq(s){font-size: 20px;}
	@include mq(m){font-size: 22px;}
	@include mq(l){font-size: 24px;}
}

img {
	max-width: 100%;
	height: auto;
}



@media (max-width:768px) {
	a {font-size: 36px;}
	a span {display: none;}
}

/* ==========================================================================
   Header
   ========================================================================== */

header {
	position: relative;
	h1 {
		margin: 0 0 1em 0;
		letter-spacing: 5px; 
		font-size: 6vw;
		@include mq(s){font-size: 5vw;}
		@include mq(m){font-size: 4vw;}
		@include mq(l){font-size: 3vw;}
	}
	h2 {
		font-size: 14px;
		@include mq(m){
			font-size: 24px;
		}
		margin: 0 0 1em 0;
	}
	h3 {
		font-size: 20px;
		margin: 0;
		margin: 0 0 1em 0;
	}
	ul.contact {
		padding: 0; margin: 0;
		text-align: center;
		li {
			list-style: none;
			display: inline-block;
			padding: 10px;
		}
	}	
}

/* ==========================================================================
   Navigation
   ========================================================================== */

nav.menu-container {
	@include mq(m){
		position: absolute;
		bottom: 0;
		right: 0;
		ul.nav {
			margin: auto;
			padding: 0;
			li {
				display: inline-block;
				position: relative;
				&.current-menu-item {
					a {
						font-weight: 700;
					}
				}
				&.menu-item-has-children {
					> a:after {
						content: '▾';
						margin-left: 10px;
					}
				}
				a {
					display: block;
					padding: 12px 18px;
				}
				ul {
					display: none;
					position: absolute;
					width: 200px;
					z-index: 999;
					margin: 0;
					padding: 0;
					li {
						background: #333;
						text-align: left;
						width: 100%;
						a {
							width: 100%;
							color: white;
						}
					}
				}
				&:hover > ul {
					display: inline-block;
					animation: fadeIn .25s;
				}
			}
		}
	}
}


/* ==========================================================================
   Masthead
   ========================================================================== */
   
#masthead {
	width: 100%;
	overflow: auto;
	position: relative;
	z-index: 0;
}

.slider {
	.caption {
		width: 100%;
		margin: auto;
		text-align: left;
		padding:5%;
		background: black;
		@include mq(m){
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left: 5%;
			width: 50%;
			padding:3%;
			background: rgba(0,0,0,.8);
		}
		h2 {
			color: white;
			margin: 0 0 0.5em 0;
		}
		p {
			color: #f2f2f2;
			margin:0 0 1em 0;
			font-size: 14px;
			@include mq(m){font-size: 18px}
		}
	}			
	img {
		width: 100%;
		height: auto !important;
	}
}

/* ==========================================================================
   Home
   ========================================================================== */
   
#intro {}


/* ==========================================================================
   Content
   ========================================================================== */

.banner {
	height: 200px;
	@include mq(s){
		height: 400px;
	}
	background-repeat: no-repeat;
	position: relative;
}

.breadcrumbs {
	border-bottom: 1px dotted #ccc;
	padding-bottom: 10px;
	margin-bottom: 2em;
	font-size: 14px;
}

.bg-image {
	background-size: cover;
	background-position: center center;
}

article {

}   


#instafeed {
	width: 100%;
	a {
		display: block; width: 33.333%; float: left; box-sizing: border-box; padding: 1%;
		img {
			width: 100%; height: auto;
		}
	}
}




/* ==========================================================================
   Footer
   ========================================================================== */


footer {
	background: #333;
	text-align: center;
	padding: 50px 0;
	p {
		color: #ccc;
	}
	ul.menu,
	ul.social-icons {
		padding: 0;
		margin: 0 0 2em 0;
		text-align: center;
		li {
			list-style: none;
			display: inline-block;
			margin:0 5px;
			a {
				color: white;
			}
		}
	}
	.fa {
		font-size: 24px;
		margin: 0 10px;
	}
	a {
		color: white;
	}
	small {
		color: #999;
	}
}
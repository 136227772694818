/* == WordPress WYSIWYG Editor Styles == */

.alignleft, img.alignleft {
	margin-right: 1.5em;
	display: inline;
	float: left;
}

.alignright, img.alignright {
	margin-left: 1.5em;
	display: inline;
	float: right;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

.wp-caption {
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px;
}

.wp-caption img {
	border: 0 none;
	padding: 0;
	margin: 0;
}

.wp-caption p.wp-caption-text {
	line-height: 1.5;
	font-size: 10px;
	margin: 0;
}



.gallery {
	display: block;
	margin-left: -10px !important;
	margin-right: -10px !important;
	dl {
		margin: 0;
		dt {
			overflow: hidden !important;
			margin: 0px 10px 10px 10px;
			img {
				width: 100%;
				height: auto;
				border: none !important;
				transition: all .5s;
			}
			&:hover {
				img {
					transform: scale(1.05);
				}
			}
		}
	}
}

form {
	box-sizing: border-box;
	label {
		display: block;
		font-size: 14px;
		font-weight: bold;
		padding-top: 10px;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		width: 100%;
		padding: 5px;
		border: 1px solid #ccc;
		font-size: 16px;
		box-shadow: 0px 0px 0px #ccc;
		transition: all .5s;
		&:focus {
			outline: none;
			border-color: #4d4d4d;
			box-shadow: 0px 0px 5px #ccc;
		}
	}
	p {
		width: 100%;
		overflow: visible;
		clear: both;
		padding: 5px 0;
		margin: 0;
	}
	input[type="submit"]{
		color: white;
		background: $primary;
		border: none;
		padding: 10px 20px;
		font-size: 16px;
		&:focus {
			outline: none;
			opacity: 0.5;
		}
	}
}


button,
a.btn {
	display: inline-block;
	background: $primary;
	border: 1px solid $primary;
	color: white;
	padding: 10px 30px;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 700;
	&:hover {
		color: white;
	}
	&.ghost {
		background: none;
		color: $primary;
	}
}
@media screen and (max-width:768px) {

	// colours
	
	$hamburger_colour: 	$primary;
	$menu_bg:			$primary;
	$textColour:	 	#fff;
	
	// spacing
	
	$space:				1em;
	$bun_space:			15px;
	$burger_width:		4px;
	
	.hamburger{
		text-align:right;
		.burger {
			position: absolute;
			top:25px;
			right: 25px;
			z-index: 2;
		}
		.bun{
			display:inline-block;
			padding:0;
			width:auto;
			> span{
				display:block;
				height:$burger-width;
				width:$bun_space*1.5;
				margin:3px 0;
				background:$hamburger_colour;
			}
		}
		&.open{
			.bun{
				span{
					position:relative; margin:0;
					&:nth-child(2){
						opacity:0;
					}
					&:nth-child(1){
						transform:rotate(405deg);
						top:5px;
					}
					&:nth-child(3){
						transform:rotate(315deg);
						top:-3px;
					}
				}
			}
		}
		.helper{
			display:inline-block; margin:0;
			position:relative; top:-$bun_space*0.7;
			color:#333;
			font-size:0.8em;
		}
		&.open{
			.helper{ top:-100px; }
		}

		ul{
			list-style:none;
			margin:0;
			padding:0;
			background:$menu_bg;
			li{
				display:block;
				border-bottom: 1px solid rgba(0,0,0,.2);
				a{
					display:block;
					padding:$bun_space;
					text-decoration:none;
					text-align:left;
					font-size: 16px;
					color:$textColour;
				}
				&:last-child > a{ border:0; }
				ul{
					border:0;
					margin: 0;
					li{
						border-bottom: none;
						a {
							font-size: 14px;
						}
						>ul li a{
							padding-left:$bun_space*1.5;
						}
					}
				}
				>ul { background:darken($menu_bg,4%); }
				>ul>li>ul{
					background:darken($menu_bg,8%);
					a{ }
				}
				>ul>li>ul>li>ul{
					background:darken($menu_bg,12%);
				}
				&.parent{
					position:relative;
					.dropper{
						position:absolute; right:0;
						padding:$bun_space $bun_space*1.5;
						cursor:pointer;
						color:$textColour;
						font-family:"FontAwesome";
					}
					&.open{
						> .dropper{
							transform:rotateX(180deg);
						}
						>a{
						}
					}
				}
			}
		}
	}
}